import React from "react";
import Helmet from "react-helmet";

import Layout from "components/Layout";
import Container from "components/Container";

const AboutPage = () => {
  return (
    <Layout pageName="about">
      <Helmet>
        <title>Jesus vera - About</title>
      </Helmet>
      <Container>
        <h1>About</h1>
        <p>
          I am a Mexican living in London with more than 15 years working in the
          industry of Software Development.
        </p>
        <p>
          Initially, I came to the UK to study for an MSc in Data Science, but
          after completing my studies successfully, a job opportunity arose in
          London and I moved again.
        </p>
        <p>
          Currently, I am working in{" "}
          <a href="https://tabled.io" target="blank">
            Tabled
          </a>{" "}
          as a Lead Software Engineer. Tabled is a startup company that is
          dedicated to do Lawyers' lives easier by making more efficient the way
          they are working. I can say that I have learned so much by working in
          this company as I have touched and worked on every area of Software
          Development.
        </p>
        <p>
          I would like to continue developing all my skills as a Sofware
          Engineer, but more importantly the skills as a Leader. I have noticed
          that the industry needs more Software Engineers with more empathy and
          social skills, hence my decision to continue as a Leader, so I can
          help others to grow more in those areas.
        </p>
      </Container>
    </Layout>
  );
};

export default AboutPage;
